const Partner = {
  "Partner.relationship.AddedOn": "Added on",
  "Partner.relationship.UpdatedOn": "Updated on",
  "Partner.relationship.UserUID": "User UID",
  "Partner.relationship.UserFirstName": "First name",
  "Partner.relationship.UserLastName": "Last name",
  "Partner.relationship.UserEmail": "Email",
  "Partner.relationship.Roles": "Roles",
  "Partner.relationship.Action": "Action",
  "Partner.relationship.Application Name": "Application name",
  "Partner.relationship.BreedName": "Breed name",
  "Partner.relationship.BreedSpeciesdName": "Breed speciesd name",
  "Partner.relationship.B2CRelationships": "B2C relationships",
  "Partner.relationship.B2BRelationships": "B2B relationships",
  "Partner.relationship.BreedList": "Breed list",
};

export default Partner;
