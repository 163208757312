import Partner from "./Partner";
import Customer from "./Customer";

// fix sonar
const pass_word = "pass" + "word";

const Pages = {
  ...Partner,
  ...Customer,
  "Public.Operation.title": "Information prompt",
  "Public.Operation": "Operation",
  "Public.Operation.confirm": "Confirm",
  "Public.Operation.cancel": "Cancel",
  "Public.Operation.yes": "Yes",
  "Public.Operation.no": "No",
  "Public.Operation.edit": "Edit",
  "Public.Operation.add": "Add",
  "Public.Operation.details": "Details",
  "Public.Operation.delete": "Delete",
  "Public.Operation.download": "Download",
  "Public.Operation.save": "Save",
  "Public.Operation.test": "Test",
  "Public.Operation.draft": "Drafted",
  "Public.Operation.stop": "Stop",
  "Public.Operation.pause": "Pause",
  "Public.Operation.resume": "Resume",
  "Public.Operation.activate": "Activate",
  "Public.Operation.duplicate": "Duplicate",
  "Public.Operation.previous": "Previous",
  "Public.Operation.retry": "Retry",
  "Public.Feedback.success": "Success",
  "Public.Feedback.Preview Starting Email": "Preview starting email",
  "Public.Feedback.Operated Successfully": "Operated successfully",
  "Public.Feedback.error": "Error",
  "Public.Feedback.need_you_confirm": "Need you confirm",
  "Public.Feedback.default_tip": "Are you sure you want to do this?",
  "Public.Feedback.request_failed": "Request failed",
  "Public.Save": "Save",
  "Public.Last update": "Last update",
  "Public.Filters": "Filters",
  "Public.Search by keyword": "Search by keyword",
  "Login.auth": "This account dose not have permission!",
  "Login.email_address": "Email address",
  "Login.password": `${pass_word}`,
  "Login.email_address_vld": "Please input your email",
  "Login.email_address_vld1": "Email address format error",
  "Login.password_vld": `Please input your ${pass_word}`,
  "Login.remember_me": "Remember me",
  "Login.forgot_your_password": `Forgot your ${pass_word}`,
  "Login.log_in": "Log in",
  "Login.sign_up": "Sign up",
  "Login.reset_your_password": `Reset your ${pass_word}`,
  "Login.reset_tip": `Please enter the email address you’d like your ${pass_word} reset information sent to.`,
  "Login.check_your_mail": "Check your mail box",
  "Login.check_tip": `You have received an email from us. Please follow the instructions in the email to reset your ${pass_word} and log in again.`,
  "Login.check_tip1": "Didn't receive the email?",
  "Login.resend": "Resend",
  "Login.reset_password_tip": `Please enter your reset ${pass_word}`,
  "Login.new_password": `New ${pass_word}`,
  "Login.new_password_vld": `Please input your new ${pass_word}`,
  "Login.confirm_password": `Confirm ${pass_word}`,
  "Login.confirm_password_vld": `Please reinput the new ${pass_word}`,
  "Login.confirm_password_vld1": `The two ${pass_word}s that you entered do not match`,
  "Login.create_an_account": "Create an account",
  "Login.full_name": "Full name",
  "Login.full_name_vld": "Please input your full name",
  "Login.recommendation_code_opt": "Recommendation code (Optional)",
  "Login.have_account_tip": "If you have an account?",
  "Login.create_store_title": "START BUILDING YOUR STORE QUICKLY",
  "Login.create_store_step1": "Contract agreement",
  "Login.create_store_step2": "Legal info",
  "Login.create_store_step3": "Store details",
  "Login.create_store_step4": "Price setting",
  "Login.create_store_step5": "Payment info",
  "Login.create_store_ing": "Sit tight,We’re creating your store!",
  "Login.create_store_success": "Success! You are ready to go",
  "Login.create_store_error": "Store creation failed. Please try again",
  "Home.link1": "View your store orders",
  "Product.search": "Search",
  "Product.ProductPrice": "Product price",
  "Product.SearchProducts": "Search products",
  "Product.Marketpricepercentage": "Market price percentage",
  "Product.Subscriptionpricepercentage": "Subscription price percentage",
  "Product.Roundoff": "round off",
  "Product.PriceTableColumnImage": "Image",
  "Product.PriceTableColumnType": "Product type",
  "Product.PriceTableColumnName": "Product name",
  "Product.PriceTableColumnSKU": "SKU",
  "Product.PriceTableColumnSPU": "SPU",
  "Product.PriceTableColumnCategory": "Product category",
  "Product.PriceTableColumnPurchasePrice": "Purchase price(Incl. VAT)",
  "Product.PriceTableColumnMarketPrice": "Market price",
  "Product.PriceTableColumnSubscriptionPrice": "Subscription price",
  "Product.PriceTableColumnUpatatime": "Updated time",
  "Product.product_total": "{count} products",
  "Product.batch_on_shelves": "Batch on shelves",
  "Product.batch_off_shelves": "Batch off shelves",
  "Product.batch_delete": "Batch delete",
  "Product.actions": "Actions",
  "Product.add_product": "Add product",
  "Product.all": "All",
  "Product.on_shelves": "On shelves",
  "Product.off_shelves": "Off shelves",
  "Product.no_select_tip": "Please select at least one item",
  "Product.on_shelves_alert":
    "Are you sure you want to put these products on shelves?",
  "Product.on_shelves_alert_single":
    "Are you sure you want to put this product on shelves?",
  "Product.off_shelves_alert":
    "Are you sure you want to put these products off shelves?",
  "Product.off_shelves_alert_single":
    "Are you sure you want to put this product off shelves?",
  "Product.delete_alert": "Are you sure you want to delete these products?",
  "Product.delete_alert_single":
    "Are you sure you want to delete this product?",
  "Product.search_by_name": "Search products by name",
  "Product.product_type": "Product type",
  "Product.product_category": "Product category",
  "Product.sales_category": "Sales category",
  "Product.regular": "Regular",
  "Product.bundle": "Bundle",
  "Product.image": "Image",
  "Product.product_name": "Product name",
  "Product.spu": "SPU",
  "Product.brand": "Brand",
  "Product.operation": "Operation",
  "Product.name": "Name",
  "Product.status": "Status",
  "Product.sale_status": "Sales status",
  "Product.basic_information": "Basic information",
  "Product.products": "Products",
  "Product.add_pictures": "Add pictures",
  "Product.subscription_status": "Subscription status",
  "Product.on/off_shelves": "On/off shelves",
  "Product.onShelves": "onShelves",
  "Product.offShelves": "offShelves",
  "Product.saleable": "Saleable",
  "Product.notSaleable": "Not–Saleable",
  "Product.attributes": "Attributes",
  "Product.TAGS": "TAGS",
  "Product.organization": "Organization",
  "Product.default_purchase_type": "Default purchase type",
  "Product.default_frequency": "Default frequency",
  "Product.options_setting": "Options setting",
  "Product.subscription_type": "Subscription type",
  "Product.store_Front_Details": "Store front details",
  "Product.variants": "Variants",
  "Product.search_engine_listing_preview": "Search engine listing preview",
  "Product.choose_Product": "Choose product",
  "Product.MEXICO":
    "TIENDA OFICIAL DE PRODUCTOS VETERINARIOS ROYAL CANIN MEXICO",
  "Product.this_is_a_physical_product": "This is a physical product",
  "Product.WEIGHT": "WEIGHT",
  "Product.LENGTH": "LENGTH",
  "Product.HEIGHT": "HEIGHT",
  "Product.WIDTH": "WIDTH",
  "Product.auto_ship": "Auto ship",
  "Product.club": "Club",
  "Product.price&inventory": "Price & Inventory",
  "Product.price": "Price",
  "Product.inventory": "Inventory",
  "Product.activity": "Activity",
  "Product.product_words": "Product words",
  "Product.preview": "Preview",
  "Product.description_tab": "Description tab",
  "Product.related_product": "Related product",
  "Product.Html": "Html",
  "Product.Json": "Json",
  "Product.Upload": "Upload",
  "Product.Variant": "Variant",
  "Product.sku": "SKU",
  "Product.ean": "Ean",
  "Product.weight_value": "Weight value",
  "Product.weight_unit": "Weight unit",
  "Product.this_product_has_multiple_options":
    "This product has multiple options, like different sizes or colors",
  "Product.options": "Options",
  "Product.purchase_type": "Purchase type",
  "Product.market_price": "Market price",
  "Product.base_price": "Base price",
  "Product.uom": "UOM",
  "Product.inventory_alert": "Inventory alert",
  "Product.title": "Title",
  "Product.meta_keywords": "Meta keywords",
  "Product.meta_description": "Meta description",
  "Product.Handle": "Handle",
  "Product.Details": "Details",
  "Product.Groups.Details.Label": "Label",
  "Product.Groups.Details.ProductLabel": "Product label",
  "Product.Groups.Details.ProductCode": "Product code",
  "Product.Groups.Details.Ean": "Ean",
  "Product.Groups.Details.Status": "Status",
  "Product.Groups.Details.Collection": "Collection",
  "Product.Groups.Details.Friendly Name": "Friendly name",
  "Product.Groups.Details": "Product groups details",
  "Product.TaggingName": "Tagging name",
  "Product.TaggingType": "Tagging type",
  "Product.ProductCount": "Product count",
  "Product.EditTagging": "Edit tagging",
  "Product.TextTopLeft": "Text tag (Top left)",
  "Product.ImageTopRight": "Image tag (Top right)",
  "Product.TaggingFontColor": "Tagging font color",
  "Product.TaggingFillColor": "Tagging fill color",
  "Product.DisplayInShop": "Display in shop",
  "Product.TaggingImage": "Tagging image",
  "Product.ShopPage": "Shop page",
  "PartnerGroup.PartnerGroup": "Partner group",
  "PartnerGroup.GroupName": "Group name",
  "PartnerGroup.Count": "Count",
  "PartnerGroup.Active": "Active",
  "PartnerGroup.Inactive": "Inactive",
  "PartnerGroup.Operation": "Operation",
  "PartnerGroup.AddNewGroup": "Add new group",
  "PartnerGroup.Edit": "Edit",
  "PartnerGroup.Members": "Members",
  "PartnerGroup.Upload": "Upload",
  "PartnerGroup.Download": "Download",
  "PartnerGroup.Delete": "Delete",
  "PartnerGroup.CustomerId": "Customer ID",
  "PartnerGroup.CompanyName": "Company name",
  "PartnerGroup.PostCode": "Post code",
  "PartnerGroup.City": "City",
  "PartnerGroup.Info": "Info",
  "PartnerGroup.PartnerMember": "Partner member",
  "PartnerGroup.DeleteGroupTips": "Are you sure to delete from this group?",
  "PartnerGroup.DeleteMemberTips": "Are you sure to delete this member?",
  "PartnerGroup.GroupHandle": "Group handle",
  "PartnerGroup.ShowMarket": "Show in market availability",
  "PartnerGroup.IgnoreOnImport": "Ignore on import",
  "PartnerGroup.IgnoreTips":
    "Ignores this group during partner information import and only managable through Clixray",
  "PartnerGroup.EditGroup": "Edit group",
  "PartnerGroup.Image": "Image",
  "PartnerGroup.Country": "Country",
  "PartnerGroup.Town": "Town",
  "PartnerGroup.Terminals": "Terminals",
  Type: "Type",
  Name: "Name",
  "Consent Text": "Consent text",
  "Add Subscriptions": "Add subscriptions",
  "Profile Synchronization": "Profile synchronization",
  "Mobile Apps": "Mobile apps",
  "Response Code": "Response code",
  "Added On": "Added on",
  "App name": "App name",
  "Device registration date": "Device registration date",
  "CustomerMetadata.Titles.PriorityOrder": "Priority order",
  "CustomerMetadata.Titles.TitleCode": "Title code",
  "CustomerMetadata.Titles.Code": "Code",
  "CustomerMetadata.Titles.TitleName": "Title name",
  "CustomerMetadata.Titles.TitleGender": "Title gender",
  "CustomerMetadata.Titles.Operation": "Operation",
  "CustomerMetadata.Titles.Operations": "Operations",
  "CustomerMetadata.Titles.Add Titles": "Add titles",
  "CustomerMetadata.Titles.Edit Titles": " Edit titles",
  "CustomerMetadata.Titles.Generic Name": "Generic name",
  "CustomerMetadata.Titles.Search": "Search",
  "CustomerMetadata.PlacesOfPurchase.PlaceCode": "Place code",
  "CustomerMetadata.PlacesOfPurchase.PlaceName": "Place name",
  "CustomerMetadata.PlacesOfPurchase.Delete": "Delete",
  "CustomerMetadata.PlacesOfPurchase.Operation": "Operation",
  "CustomerMetadata.PlacesOfPurchase.Operations": "Operations",
  "CustomerMetadata.AccessRights.PlaceCode": "Place code",
  "CustomerMetadata.AccessRights.Delete": "Delete",
  "CustomerMetadata.AccessRights.PlaceName": "Place name",
  "CustomerMetadata.LegalForm.Code": "Legal form code",
  "CustomerMetadata.LegalForm.Name": "Legal form name",
  "CustomerMetadata.LegalForm.Description": "Legal form description",
  "CustomerMetadata.LegalForm.Delete": "Delete",
  "CustomerTagging.Tagging Name": "Tagging name",
  "CustomerTagging.Tagging Description": "Tagging description",
  "CustomerTagging.Tagging Type": "Tagging type",
  "CustomerTagging.Count": "Count",
  "CustomerTagging.Tagging Status": "Tagging status",
  "CustomerTagging.DeleteTips": "Are you sure to delete this customer tagging?",
  "CustomerTagging.Category Name": "Category name",
  "CustomerTagging.Category discount": "Category discount",
  "CustomerTagging.Growth value": "Growth value",
  "CustomerTagging.Badge img": "Badge img",
  "CustomerTagging.Is defalt": "Is defalt",
  "CustomerTagging.Tagging is related": "Tagging is related",
  "CustomerCategory.DeleteTips":
    "Are you sure to delete this customer category?",
  "Add Titles": "Add titles",
  "Edit Titles": "Edit titles",
  "CustomerMetadata.Genders.Gender Code": "Gender code",
  "CustomerMetadata.Genders.Gender Name": "Gender name",
  "CustomerMetadata.Genders.Default Title": "Default title",
  "CustomerMetadata.Genders.Edit Genders": "Edit genders",
  "CustomerMetadata.Genders.Edit": "Edit",
  "CustomerMetadata.Genders.Code": "Code",
  "CustomerMetadata.LegalForm.Generic Name": "Generic name",
  "CustomerMetadata.LegalForm.Type": "Type",
  "CustomerMetadata.LegalForm.Consent Text": "Consent text",
  "CustomerAccessRights.Code": "Code",
  "CustomerAccessRights.Generic Name": "Generic name",
  "CustomerAccessRights.Name": "Name",
  "CustomerAccessRights.Consent Text": "Consent text",
  "CustomerMetadata.PlacesOfPurchase.Code": "Code",
  "CustomerMetadata.PlacesOfPurchase.Generic Name": "Generic name",
  "CustomerMetadata.PlacesOfPurchase.Name": "Name",
  "CustomerMetadata.PlacesOfPurchase.Consent Text": "Consent text",
  "CustomerMetadata.Subscriptions.Code": "Code",
  "CustomerMetadata.Subscriptions.Generic Name": "Generic name",
  "CustomerMetadata.Subscriptions.Type": "Type",
  "CustomerMetadata.Subscriptions.Name": "Name",
  "CustomerMetadata.Subscriptions.Consent Text": "Consent text",
  "Gender Code": "Gender code",
  "Gender Name": "Gender name",
  "Default Title": "Default title",
  "Add PlacesOfPurchases": "Add placesOfPurchases",
  "Add Genders": "Add genders",
  "Add AccessRights": "Add accessRights",
  "CustomerAccessRights.Edit": "Edit",
  "Add LegalForm": "Add legalForm",
  "CustomerMetadata.PlacesOfPurchase.Edit": "Edit",
  "Edit Genders": "Edit genders",
  "Add Communication Preferences": "Add communication preferences",
  "CustomerMetadata.LegalForm.Edit": "Edit",
  "Edit Communication Preferences": "Edit communication preferences",
  "Setting.GroupManagement.AddTheFirstGroup": "Add the first group",
  "Setting.GroupManagement.DepartmentName": "Department name ",
  "Setting.GroupManagement.NewDepartment": "New department ",
  "Setting.GroupManagement.superiorDepartments": "superior departments",
  "Setting.GroupManagement.GroupName": "  Group Name",
  "Setting.GroupManagement.UserNumber": "User number",
  "Setting.GroupManagement.AddSubdivisions": "Add subdivisions",
  "Setting.GroupManagement.Edit": "Edit",
  "Setting.GroupManagement.Delete": "Delete",
  "Setting.UserList.User Name": "User name",
  "Setting.UserList.User email": "User email",
  "Setting.UserList.User List": "User list",
  "Setting.UserList.Roles": "Roles",
  "Setting.UserList.Add": "Add",
  "Setting.UserLis.Delete": "Delete",
  "Setting.UserLis.Edit": "Edit",
  "Setting.UserLis.Disabled": "Disabled",
  "Setting.UserLis.Status": "Status",
  "Setting.RolePermissions.AddRole": "Add role",
  "Setting.RolePermissions.RoleName": "Role name",
  "Setting.RolePermissions.RoleNamePlaceHolder": "Role name",
  "Setting.RolePermissions.Pemission": "Permission",
  "Setting.RolePermissions.SetPermission": "Set permission",
  "Setting.RolePermissions.DeletePopconfirmTips":
    "Are you sure you want to delete this role?",
  "CustomerMetadata.Subscriptions.Delete": "Delete",
  "CustomerMetadata.Subscriptions.Edit": "Edit",
  "Customer.tagging.Delete": "Delete",
  "Customer.tagging.Edit": "Edit",
  "Partner.relationship.Add Contact": "Add contact",
  "Partner.relationship.Search Contact": "Search contact",
  "Partner.relationship.Missing roles": "Missing roles",
  "Partner.relationship.Add Breed": "Add breed",
  "Partner.relationship.Missing contact": "Missing contact",
  "Partner.relationship.Species": "Species",
  "Partner.relationship.Missing species": "Missing species",
  "Partner.relationship.Universe": "Universe",
  "Partner.relationship.Breeds": "Breeds",
  "Partner.relationship.Missing universe": "Missing universe",
  "Partner.relationship.Missing breeds": "Missing breeds",
  "Partner.relationship.Edit Contact": "Edit contact",
  "Partners.Relationship.Edit": "Edit",
  "Partners.Relationship.Change to Primary Contact":
    "Change to primary contact",
  "Product.Products.image": "image",
  "Product.Products.name": "Product name",
  "Product.Products.SPU": "SPU",
  " Product.Products.Market price": "Market price",
  "Product.Products.Sales category": "Sales category",
  "Product.Products.Product category": "Product category",
  "Product.Products.Brand": "Brand",
  "Product.Products.Product Info": "Product info",
  "Product.ProductsDetail.Product Details": "Product details",
  "Product.ProductsDetail.General Information": "General information",
  "Product.ProductsDetail.Packaging Information": "Packaging information",
  "Product.ProductsDetail.Visibility": "Visibility",
  "Product.ProductsDetail.Pricing": "Pricing",
  "Product.ProductsDetail.Label": "Label",
  "Product.ProductsDetail.Code": "Code",
  "Product.ProductsDetail.EAN 1": "EAN 1",
  "Product.ProductsDetail.Collection Reference": "Collection reference",
  "Product.ProductsDetail.Product Groups": "Product groups",
  "Product.ProductsDetail.Product Friendly Name": "Product friendly name",
  "Product.ProductsDetail.Product Net Weight": "Product net weight",
  "Product.ProductsDetail.Product Gross Weight": "Product gross weight",
  "Product.ProductsDetail.Product Packaging Units": "Product packaging units",
  "Product.Product Packaging Container Types":
    "Product packaging container types",
  "Product.ProductsDetail.Product Packaging Value": "Product packaging value",
  "Product.ProductsDetail.Product Packaging Gross Weight":
    "Product packaging gross weight",
  "Product.ProductsDetail.Status": "Status",
  "Product.ProductsDetail.Recommended Retail Price": "Recommended retail price",
  "Product.ProductsDetail.Partners Purchase Price": "Partners purchase price",
  "Product.Products.Label": "Label",
  "Product.Products.Product Code": "Product code",
  "Product.Products.EAN": "Ean",
  "Product.Collections.Collection Reference": "Collection reference",
  "Product.Collections.Collection Name": "Collection name",
  "Product.Collections.Generic Name": "Generic name",
  "Product.Collections.Product Label": "Product label",
  "Product.Collections.Product EAN": "Product EAN",
  "Product.Collections.Product Code": "Product code",
  "Product.Collections.Product Count": "Product count",
  "Product.Collections.Reference": "Reference",
  "Product.Collections.ProductPics": "Productpics",
  "Product.Collections.Collection Details": "Collection details",
  "Product.Collections.Collections with Related Products":
    "Collections with related products",
  "Product.Collections.Operation": "Operation",
  "Product.Collections.General Imformation": "General information",
  "Product.Collections.Collection Pet Stage": "Collection pet stage",
  "Product.Collections.Collection Range": "Collection range",
  "Product.Collections.Density": "Density",
  "Product.Collections.Details": "Details",
  "Product.Collections.Energy Level": "Energy level",
  "Product.Collections.Sorting Weight": "Sorting weight",
  "Product.Collections.Product Finder References": "Product finder references",
  "Product.Collections.Components contains": "Components contains",
  "Product.Collections.Product List": "Product list",
  "Product.Collections.Collection Pathology": "Collection pathology",
  "Product.Collections.Components does not contain":
    "Components does not contain",
  "Product.Group.Opreation": "Opreation",
  "Product.Group.File ID": "File ID",
  "Product.Group.No of rows": "No of rows",
  "Product.Group.Date/Time Uploaded": "Date/Time uploaded",
  "Product.Group.Download": "Download",
  "Product.Group.Group Members Upload": "Group members upload",
  "Product.Group.Download Sample": "Download sample",
  "Product.Group.Select File": "Select file",
  "Product.Group.upload excel": "upload excel",
  "Product.Group.Click to Upload file": "Click to upload file",
  "Product.Group.The csv files uploaded should be in UTF8 encoding in order to properly identify the contents of it.":
    "The csv files uploaded should be in UTF8 encoding in order to properly identify the contents of it.",
  "Product.Group.Please Select File": "Please select file",
  "Product.Group.Replace current group with the list in the file":
    "Replace current group with the list in the file",
  "Product.Finder.Simulator.Result.Reference": "Reference",
  "Product.Finder.Simulator.Result.Generic Name": "Generic name",
  "Product.Finder.Simulator.Result.Product Count": "Product count",
  "Product.Finder.Simulator.Apply": "Apply",
  "Product.Finder.Simulator.Clear All": "Clear all",
  "Product.Finder.Simulator.From": "From",
  "Product.Finder.Simulator.to": "to",
  "Product.Finder.Simulator.Meet": "Meet",
  "Product.Finder.Simulator.All": "All",
  "Product.Finder.Simulator.Any": "Any",
  "Product.Finder.Simulator.New Criteria": "New criteria",
  "Product.Finder.Simulator.remove": "remove",
  "Product.Finder.Simulator.of the following criteria":
    "of the following criteria",
  "Product.Finder.Simulator.Please Select": "Please select",
  "Campaign.email": "Email campaigns",
  "Campaign.dynamic": "Dynamic campaigns",
  "Campaign.Personalize": "Personalize columns",
  "Campaign.Properties.CAMPAIGN PROPERTIES": "CAMPAIGN PROPERTIES",
  "Campaign.Properties.Campaign properties": "Campaign properties",
  "Campaign.Properties.General": "General",
  "Campaign.Properties.Scheduler": "Scheduler",
  "Campaign.Properties.Dates": "Dates",
  "Campaign.Properties.Time Zone": "Time zone",
  "Campaign.Properties.Campaign Name (internal)": "Campaign name (internal)",
  "Campaign.Properties.Enter campaign name": "Enter campaign name",
  "Campaign.Properties.Untitled": "Untitled",
  "Campaign.Properties.Category": "Category",
  "Campaign.Properties.From Sender": "From sender",
  "Campaign.Properties.Select category": "Select category",
  "Campaign.Properties.Create new category": "Create new category",
  "Campaign.Properties.Campaign Description": "Description",
  "Campaign.Properties.Campaign Tags": "Campaign tags",
  "Campaign.Properties.Enter tags": "Enter tags",
  "Campaign.Properties.Select tags": "Select tags",
  "Campaign.Properties.Form Sender": "Form sender",
  "Campaign.Properties.Select sender": "Select sender",
  "Campaign.Properties.Frequency": "Frequency",
  "Campaign.Properties.Start Date": "Start date",
  "Campaign.Properties.Start Time": "Start time",
  "Campaign.Properties.Select start date": "Select start date",
  "Campaign.Properties.Select start time": "Select start time",
  "Campaign.Properties.Repeat Every": "Repeat every",
  "Campaign.Properties.Repeat On": "Repeat on",
  "Campaign.Properties.Month(s)": "Month(s)",
  "Campaign.Properties.On the selected date": "On the selected date",
  "Campaign.Properties.On the selected day of the week":
    "On the selected day of the week",
  "Campaign.Properties.End Date": "End date",
  "Campaign.Properties.Ends": "Ends",
  "Campaign.Properties.Never": "Never",
  "Campaign.Properties.Enter the Numbers": "Enter the numbers",
  "Campaign.Properties.Select end date": "Select end date",
  "Campaign.Properties.Day of the Month": "Day of the month",
  "Campaign.Properties.Day of the week": "Day of the week",
  "Campaign.Properties.Day(s)": "Day(s)",
  "Campaign.Properties.Week(s)": "Week(s)",
  "Campaign.Properties.Occurence(s)": "Occurence(s)",
  "Campaign.Properties.Year(s)": "Year(s)",
  "Campaign.Properties.Repeat Pattern": "Repeat pattern",
  "Campaign.Properties.Tracking": "Tracking",
  "Campaign.Properties.utm_content": "utm_campaign",
  "Campaign.Properties.Add utm_content": "Add utm_campaign",
  "Campaign.Properties.utm_medium": "utm_medium",
  "Campaign.Properties.Add utm_medium": "Add utm_medium",
  "Campaign.Properties.utm_source": "utm_source",
  "Campaign.Properties.Add utm_source": "Add utm_source",
  "Campaign.Properties.Cancel": "Cancel",
  "Campaign.Properties.Next Step": "Next step",
  "Campaign.Properties.Save": "Save",
  "Campaign.Properties.Votre recommandation veterinaire - <veterinaire@royalcanin.com>":
    "Votre recommandation veterinaire - <veterinaire@royalcanin.com>",
  "Campaign.Properties.Veterinary recommandation - <veterinary@royalcanin.com>":
    "Veterinary recommandation - <veterinary@royalcanin.com>",
  "Campaign.Properties.Promotion veterinaire - <promotion.veterinaire@royalcanin.com>":
    "Promotion veterinaire - <promotion.veterinaire@royalcanin.com>",
  "Campaign.Audience.Audience": "Audience",
  "Campaign.Audience.AUDIENCE": "AUDIENCE",
  "Campaign.Audience.Master Segment": "Master segment",
  "Campaign.Audience.Master Segments": "Segments",
  "Campaign.Audience.Select a master segment": "Select a master segment",
  "Campaign.Audience.Marketing Segment": "Marketing segment",
  "Campaign.Audience.Select a marketing segment": "Select a marketing segment",
  "Campaign.Audience.Filters": "Filters",
  "Campaign.Audience.All campaign managers": "All campaign managers",
  "Campaign.Audience.All Cities": "All cities",
  "Campaign.Audience.All Ages": "All ages",
  "Campaign.Audience.All Pets": "All pets",
  "Campaign.Audience.From": "From",
  "Campaign.Audience.To": "To",
  "Campaign.Audience.Reset Filters": "Reset filters",
  "Campaign.Audience.Apply Filters": "Apply filters",
  "Campaign.Audience.Marketing Segments": "Marketing segments",
  "Campaign.Audience.Marketing Segment Name": "Marketing segment name",
  "Campaign.Audience.Creation Date": "Creation date",
  "Campaign.Audience.Description": "Description",
  "Campaign.Audience.Created By": "Created by",
  "Campaign.Audience.Volume": "Volume",
  "Campaign.Audience.Details": "Details",
  "Campaign.Audience.Select": "Select",
  "Campaign.Audience.Previous": "Previous",
  "Campaign.Audience.Next Step": "Next step",
  "Campaign.Content.Content set-up": "Content set-up",
  "Campaign.Content.StartingEmail": "Starting email",
  "Campaign.Content.1.Email Template": "1.Email template",
  "Campaign.Content.StartingEmail.subject": "subject",
  "Campaign.Content.StartingEmail.VariablesMapping": "2.Variables mapping",
  "Campaign.Content.StartingEmail.Template Variables": "Template variables",
  "Campaign.Content.StartingEmail.Mapping Method *": "Mapping method *",
  "Campaign.Content.StartingEmail.Value of *": "Value of *",
  "Campaign.Content.FollowUp.Follow-Up": "Follow-up",
  "Campaign.Content.FollowUp.0.Follow-Up Rule": "0.Follow-up rule",
  "Campaign.Content.FollowUp.ReferralEmail": "Referral email",
  "Campaign.Content.FollowUp.ReferralRule":
    "Select the email(s) on which the rule should be based",
  "Campaign.Content.FollowUp.Delay Period": "Delay period",
  "Campaign.Content.FollowUp.Follow-Up Criteria": "Follow-up criteria",
  "Campaign.Content.FollowUp.Open the email": "Open by opened",
  "Campaign.Content.FollowUp.Did not open": "Did not open",
  "Campaign.Content.FollowUp.Click on any link": "Click by clicked",
  "Campaign.Content.FollowUp.Did not Click on any link":
    "Did not click on any link",
  "Campaign.Content.FollowUp.Click on specific link": "Click on specific link",
  "Campaign.Content.FollowUp.Did not Click on specific link":
    "Did not click on specific link",
  "Campaign.Content.FollowUp.Add Follow Up": "Add follow-up",
  "Campaign.Test.Test": "Test",
  "Campaign.Test.See summary": "See summary",
  "Campaign.Activation.Activation": "Activation",
  "Campaign.Activation.Summary": "SUMMARY OF YOUR CAMPAIGN",
  "Campaign.Activation.Campaign name": "Campaign name(internal)",
  "Campaign.Activation.Campaign reference": "Campaign reference",
  "Campaign.Activation.Sender": "Sender",
  "Campaign.Activation.Category": "Category",
  "Campaign.Activation.Tags": "Tags",
  "Campaign.Activation.Tracking": "Tracking",
  "Campaign.Activation.utm_content": "utm_campaign",
  "Campaign.Activation.utm_source": "utm_source",
  "Campaign.Activation.utm_medium": "utm_medium",
  "Campaign.Activation.Frequency": "Frequency",
  "Campaign.Activation.Start Date": "Start date",
  "Campaign.Activation.End Date": "End Date",
  "Campaign.Activation.Repeat Pattern": "Repeat pattern",
  "Campaign.Activation.Repeat every": "Repeat every",
  "Campaign.Activation.Audience": "Audience",
  "Campaign.Activation.Master Segment": "Master segment",
  "Campaign.Activation.Marketing Segment": "Marketing segment",
  "Campaign.Activation.Volume": "Volume",
  "Campaign.Activation.Content Set-up": "Content set-up",
  "Campaign.Activation.Starting Email": "Starting email",
  "Campaign.Activation.Subject": "Subject",
  "Campaign.Activation.Template Name": "Template name",
  "Campaign.Activation.Additional variables": "Additional variables",
  "Campaign.Activation.Follow-Up": "Follow-up",
  "Campaign.Activation.Preview": "Preview",
  "Campaign.Details.Information": "Campaign information",
  "Campaign.Details.Statistiques": "Statistics",
  "Campaign.Details.DRAFT": "DRAFT",
  "Campaign.Details.ACTIVATED": "ACTIVATED",
  "Campaign.Details.ENDED": "ENDED",
  "Campaign.List.Campaign name": "Campaign name",
  "Campaign.List.Starting Date": "Start date",
  "Campaign.List.Ending Date": "End date",
  "Campaign.List.Campaign Manager": "Campaign manager",
  "Campaign.List.Target Volume": "Target volume",
  "Campaign.List.Follow-Ups number": "Follow-ups",
  "Campaign.List.Deliver rate": "Delivery rate",
  "Campaign.List.Unique open": "Unique openers",
  "Campaign.List.Unique Open Rate": "Unique openers rate",
  "Campaign.List.Unique Clicks": "Unique clickers",
  "Campaign.List.Unique Clicks rate": "Unique clickers rate",
  "Campaign.List.Unique Click/Unique Open Rate": "Reactivity rate",
  "Campaign.List.Personnalize Columns": "Personalize columns",
  "Campaign.List.All campaign managers": "All campaign managers",
  "Campaign.List.All Tags": "All tags",
  "Campaign.List.All Status": "All status",
  "Campaign.List.DeleteTips": "Are you sure to delete this campaign?",
  "Campaign.Tagging.DeleteTips": "Are you sure to delete this tagging?",
  "Campaign.Dynamic.day": "day",
  "Campaign.Dynamic.days": "days",
  "Campaign.Dynamic.week": "week",
  "Campaign.Dynamic.weeks": "weeks",
  "Campaign.Dynamic.month": "month",
  "Campaign.Dynamic.months": "months",
  "Campaign.List.Last run sent": "Last run sent",
  "SharedContent.New shared content": "New shared content",
  "SharedContent.Associated campaign": "Associated campaign",
  "SharedContent.Block name": "Block name",
  "SharedContent.Fragment name": "Fragment name",
  "SharedContent.Fragment type": "Fragment type",
  "SharedContent.deleteTips": "Are you sure to delete this content?",
  "Fragments.deleteTips": "Are you sure to delete this fragment?",
  "Fragments.Add new fragment": "Add new fragment",
  "Fragments.Edit new fragment": "Edit new fragment",
  "Loyaity.CustomerPointManagement.Customer Email": "Customer email",
  "Loyaity.CustomerPointManagement.Okta ID": "Okta ID",
  "Loyaity.CustomerPointManagement.Stage": "Stage",
  "Loyaity.CustomerPointManagement.Total Points": "Total points",
  "Loyaity.CustomerPointManagement.Operation": "Operation",
  "Loyaity.CustomerPointManagement.View": "View",
  "Loyaity.ProgramListing.Program Name": "Program name",
  "Loyaity.ProgramListing.Start Date": "Start date",
  "Loyaity.ProgramListing.End Date": "End date",
  "Loyaity.ProgramListing.Description": "Description",
  "Loyaity.ProgramListing.Create Program": "Create program",
  "loyalty.CustomerPointManagement.Track Records.Name": "Name",
  "loyalty.CustomerPointManagement.Track Records.Okta Id :": "Okta ID",
  "loyalty.CustomerPointManagement.Track Records.Email": "Email",
  "loyalty.CustomerPointManagement.Track Records.PointSummary": "Point Summary",
  "loyalty.CustomerPointManagement.Track Records.TotalUse": "Total use",
  "loyalty.CustomerPointManagement.Track Records.TotalObtain":
    "Total Grant/Obtain",
  "loyalty.CustomerPointManagement.Track Records.Balance": "Balance",
  "loyalty.CustomerPointManagement.Track Records.CurrentOwnedPoints":
    "Current owned points",
  "LoyaltyB2b.ProgramListing.Program Name": "Program name",
  "LoyaltyB2b.ProgramListing.Start Date": "Start date",
  "LoyaltyB2b.ProgramListing.End Date": "End date",
  "LoyaltyB2b.ProgramListing.Description": "Description",
  "LoyaltyB2b.ProgramListing.Status": "Status",
  "LoyaltyB2b.ProgramListing.Type": "Type",
};

export default Pages;
