import { Route, Redirect, Switch } from "react-router-dom";
import r from "../../router";

// 路由拆分成数组用于匹配面包屑
const getUrlList = (url) => {
  if (!url || url === "/") {
    return ["/"];
  }
  const urlList = url.split("/").filter((i) => i);
  return urlList.map((_, index) => `/${urlList.slice(0, index + 1).join("/")}`);
};
// 一维路由
const getFlattenRoutes = (arr) =>
  arr.reduce((prev, item) => {
    return prev.concat(
      Array.isArray(item.routes)
        ? [...getFlattenRoutes(item.routes), item]
        : item
    );
  }, []);
const flattenRoutes = getFlattenRoutes(r);
const renderRoutes = (routes, extraProps = {}, switchProps = {}) =>
  routes ? (
    <Switch {...switchProps}>
      {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={(props) => {
            if (route.redirect) {
              return <Redirect to={{ pathname: route.redirect }} />;
            }
            return route.render ? (
              route.render({ ...props, ...extraProps, route: route })
            ) : (
              <route.component {...props} {...extraProps} route={route} />
            );
          }}
        />
      ))}
    </Switch>
  ) : null;
export default renderRoutes;
export { getFlattenRoutes, getUrlList, flattenRoutes };
