import en_US from "locales/en-US";
import ru_RU from "locales/ru-RU";
import { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { createContainer } from "unstated-next";

import { RouteConfig } from "../typings/router";
const locales = {
  en_US: en_US,
  ru_RU: ru_RU,
};
const warningHandler = () => ({});
type UserInfo = {
  access: Record<string, string>;
};
function useGlobalContainer() {
  const [lanInited, setLanInited] = useState(false);
  // 菜单
  const [menu, setMenu] = useState<RouteConfig[]>();
  // 菜单选中的keys
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>();
  // 当前展开的 SubMenu 菜单项 key 数组
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  // 按钮权限
  const [functionMap, setFunctionMap] = useState<Map<string, string>>(
    new Map()
  );

  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("en_US");
  const [user, setUser] = useState<UserInfo>();
  const changeLang = () => {
    setLoading(true);
    setLang(lang === "en_US" ? "ru_RU" : "en_US");
  };

  useEffect(() => {
    intl
      .init({
        currentLocale: lang,
        locales,
        warningHandler,
      })
      .then(() => {
        setLanInited(true);
        setLoading(false);
      });
  }, [lang]);
  return {
    openKeys,
    lanInited,
    loading,
    lang,
    changeLang,
    setLoading,
    setLang,
    user,
    setUser,
    menu,
    setMenu,
    selectedMenuKeys,
    setSelectedMenuKeys,
    functionMap,
    setFunctionMap,
    setOpenKeys,
  };
}
const GlobalContainer = createContainer(useGlobalContainer);
export default GlobalContainer;
