export const Enum = {
  campaignStatus: {
    activated: { label: "Activated", value: 0 },
    draft: { label: "Drafted", value: 1 },
    scheduled: { label: "Scheduled", value: 2 },
    paused: { label: "Paused", value: 3 },
    ended: { label: "Ended", value: 4 },
    resume: { label: "Resume", value: 5 },
  },
  menuTypes: {
    menu: { label: "Menu", value: 1 },
    button: { label: "Function", value: 2 },
    authority: { label: "Authority", value: 3 },
  },
  countryCode: {
    "/fr": { label: "France", value: "FR", icon: "icon-FR" },
    "/jp": { label: "Japan", value: "JP", icon: "icon-JP" },
    "/it": { label: "Italy", value: "IT", icon: undefined },
    "/au": { label: "Australia", value: "AU", icon: undefined },
    "/nz": { label: "New-Sealand", value: "NZ", icon: undefined },
  },
  fragmentTypes: {
    Seasonal: { label: "Seasonal", value: "Seasonal" },
    Promo: { label: "Promo", value: "Promo" },
  },
  sharedContentStatus: {
    unplanned: { label: "Unplanned", value: "Unplanned" },
    planned: { label: "Planned", value: "Planned" },
    active: { label: "Active", value: "Active" },
    over: { label: "Over", value: "Over" },
  },
  purchasePlace: {
    pos: { label: "Pos", value: "pos" },
    vet: { label: "Vet", value: "vet" },
  },
  partnerType: {
    clinic: { label: "Clinic", value: "clinic" },
    breeder: { label: "Breeder", value: "breeder" },
    shelter: { label: "Shelter", value: "shelter" },
    pension: { label: "Pension", value: "pension" },
    pharma: { label: "Pharma", value: "pharma" },
    club: { label: "Club", value: "club" },
    groomer: { label: "Groomer", value: "groomer" },
    pos: { label: "Pos", value: "pos" },
    web: { label: "Web", value: "web" },
    other: { label: "Other", value: "other" },
  },
  dealStatus: {
    active: {
      label: "Active",
      value: "ACTIVE",
    },
    paused: {
      label: "Paused",
      value: "PAUSED",
    },
    draft: {
      label: "Draft",
      value: "DRAFT",
    },
    scheduled: {
      label: "Scheduled",
      value: "SCHEDULED",
    },
    inactive: {
      label: "Inactive",
      value: "INACTIVE",
    },
    finished: {
      label: "Finished",
      value: "FINISHED",
    },
  },
  partnerCodeStatus: {
    available: {
      label: "Available",
      value: "AVAILABLE",
    },
    booked: {
      label: "Booked",
      value: "BOOKED",
    },
    used: {
      label: "Used",
      value: "USED",
    },
    removed: {
      label: "Removed",
      value: "REMOVED",
    },
  },
};

type keyType =
  | "campaignStatus"
  | "menuTypes"
  | "countryCode"
  | "fragmentTypes"
  | "sharedContentStatus"
  | "purchasePlace"
  | "partnerType"
  | "dealStatus";

export const getEnumList = (type: keyType) => {
  const enumType = Enum[type];
  if (Object.prototype.toString.call(enumType) === "[object Object]") {
    return Object.values(enumType);
  } else {
    return [];
  }
};

export const getEnumItem = (type: keyType, value: any) => {
  const item = getEnumList(type).find((enumList) => enumList?.value === value);
  return item || { label: "", value: value };
};

export const getEnumLabel = (type: keyType, value: any) => {
  const item = getEnumItem(type, value);
  return item?.label || value;
};

export enum Roles {
  Admin,
  Editor,
  Vet,
  VetAdmin,
  VetAssist,
  VetMuf,
}

export enum accountTypeEnum {
  test = 0,
  prod = 1,
  internal = 2,
}

export enum genderEnum {
  Female = 0,
  Male = 1,
  Other = 2,
}
