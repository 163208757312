import { createRoot } from "react-dom/client";
import App from "./App";
import "./tailwind.css";
import "./style/index.less";
import reportWebVitals from "./reportWebVitals";
import "./assets/svg";

const root = createRoot(document.getElementById("root"));

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
