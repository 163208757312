import { lazy } from "react";
import { RouteConfig } from "../typings/router";
import SuspenseComponent from "./SuspenseComponent";
const CampaignLayout = lazy(() => import("../layout/campaignLayout"));
//Campaign
const CampaignList = lazy(() => import("../pages/campaign/list"));
const CampaignEmailAdd = lazy(
  () => import("../pages/campaign/createCampaign/Email/container")
);
const CampaignTags = lazy(() => import("../pages/campaign/tags"));
const CampaignEmailDetail = lazy(
  () => import("../pages/campaign/detail/Email")
);
const CampaignDynamicAdd = lazy(
  () => import("../pages/campaign/createCampaign/Dynamic/container")
);
const CampaignDynamicDetail = lazy(
  () => import("../pages/campaign/detail/Dynamic")
);
const CampaignSharedContent = lazy(
  () => import("../pages/campaign/shared-content")
);
const CampaignSharedContentAdd = lazy(
  () => import("../pages/campaign/shared-content/SharedContent/components/add")
);
const CampaignSharedContentDetails = lazy(
  () =>
    import("../pages/campaign/shared-content/SharedContent/components/details")
);
const CampaignFragmentsAdd = lazy(
  () => import("../pages/campaign/shared-content/Fragments/components/add")
);
const CampaignFragmentsDetails = lazy(
  () => import("../pages/campaign/shared-content/Fragments/components/details")
);

const campaign: RouteConfig = {
  name: "Campaign",
  path: "/app/campaign",
  breadcrumbName: "Campaign",
  component: SuspenseComponent(CampaignLayout),
  routes: [
    {
      path: "/app/campaign",
      exact: true,
      redirect: "/app/campaign/list",
    },
    {
      name: "Campaign List",
      path: "/app/campaign/list",
      exact: true,
      breadcrumbName: "Campaign List",
      icon: "icon-category",
      component: CampaignList,
    },
    {
      name: "Campaign Tags",
      path: "/app/campaign/tags",
      exact: true,
      breadcrumbName: "Campaign Tags",
      icon: "icon-tag",
      component: CampaignTags,
    },
    {
      name: "Shared Content",
      path: "/app/campaign/shared-content",
      exact: true,
      breadcrumbName: "Shared Content",
      icon: "icon-tag",
      component: CampaignSharedContent,
    },
    {
      name: "Shared Content Add",
      path: "/app/campaign/shared-content/content-add",
      exact: true,
      breadcrumbName: "Shared Content Add",
      icon: "icon-tag",
      component: CampaignSharedContentAdd,
    },
    {
      name: "Shared Content details",
      path: "/app/campaign/shared-content/content-details/:id",
      exact: true,
      breadcrumbName: "Shared Content Details",
      icon: "icon-tag",
      component: CampaignSharedContentDetails,
    },
    {
      name: "Fragments Add",
      path: "/app/campaign/shared-content/fragment-add",
      exact: true,
      breadcrumbName: "Fragments Add",
      icon: "icon-tag",
      component: CampaignFragmentsAdd,
    },
    {
      name: "Fragments Details",
      path: "/app/campaign/shared-content/fragment-details/:id",
      exact: true,
      breadcrumbName: "Fragments Details",
      icon: "icon-tag",
      component: CampaignFragmentsDetails,
    },
    {
      name: "Campaign Properties",
      path: "/app/campaign/list/properties",
      exact: true,
      icon: "icon-team",
      component: CampaignEmailAdd,
      hideInMenu: true,
    },
    {
      name: "Campaign Details",
      path: "/app/campaign/list/details/:id",
      exact: true,
      icon: "icon-team",
      breadcrumbName: "Campaign Details",
      component: CampaignEmailDetail,
      hideInMenu: true,
    },
    {
      name: "Campaign Properties",
      path: "/app/campaign/list/dynamic-properties",
      exact: true,
      icon: "icon-team",
      component: CampaignDynamicAdd,
      hideInMenu: true,
    },
    {
      name: "Campaign Details",
      path: "/app/campaign/list/dynamic-details/:id",
      exact: true,
      icon: "icon-team",
      breadcrumbName: "Campaign Details",
      component: CampaignDynamicDetail,
      hideInMenu: true,
    },
  ],
};
export default campaign;
