import { Suspense } from "react";
import { Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";

import { getBasename } from "./utils/tools";
import routes from "./router";
import enUS from "antd/lib/locale/en_US";
import { oktaConfig, oktaScopesConfig } from "./oktaConfig";
import LoadingForRC from "./components/LoadingForRC";
import renderRoutes from "./utils/route";
import ScrollToTop from "./layout/ScrollToTop";
import GlobalContainer from "./model/GlobalContainer";
import const_config from "./utils/const_config";
import Spinner from "components/Spinner";

Spin.setDefaultIndicator(<LoadingForRC />);

const basename = getBasename();

const routerContent = (
  <Router basename={basename}>
    <ScrollToTop>
      <Suspense
        fallback={
          <div className="full flex-center">
            <Spin size="large" />
          </div>
        }
      >
        {renderRoutes(routes)}
      </Suspense>
    </ScrollToTop>
  </Router>
);
function App() {
  const idToken = sessionStorage.getItem("id-token");
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(
      toRelativeUrl(originalUri, window.location.origin + basename)
    );
  };

  return (
    <div className="App" id="app">
      <ConfigProvider
        locale={enUS}
        getPopupContainer={const_config.POPUP_CONTAINER}
      >
        <GlobalContainer.Provider>
          {/* display loader when an async action is performed but React.Suspense doesn't
              catch it */}
          <Spinner />
          {/* 如果是生产环境 挂载okta组件 */}
          {/* 测试环境如果挂载okta 热更新可能会报okta的错误信息 影响开发体验 */}
          {process.env.REACT_APP_DEVAPI === "false" ? (
            <Security
              oktaAuth={idToken ? oktaConfig : oktaScopesConfig}
              restoreOriginalUri={restoreOriginalUri}
            >
              {routerContent}
            </Security>
          ) : (
            routerContent
          )}
        </GlobalContainer.Provider>
      </ConfigProvider>
    </div>
  );
}
export default App;
