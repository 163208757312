import { lazy } from "react";
import { Redirect } from "react-router-dom";
import Logout from "../pages/logout";
import LoginCallback from "../pages/login-callback";
import Loading from "../pages/loading";
import customer from "./customer";
import partner from "./partner";
import product from "./product";
import campaign from "./campaign";
import setting from "./setting";
import loyalty from "./loyalty";
import loyalty_b2b from "./loyalty-b2b";
import deals from "./deals";
import { RouteConfig } from "../typings/router";
import { getRoutesInMenu } from "./util";

/******************
 * Layout 路由
 * ***************/
const AppLayout = lazy(() => import("../layout/index.jsx"));

/*************
 * Page 路由
 *************/
/*注释const Home = lazy(() => import('../pages/home')); */

/**
 * 路由字段说明：
 * breadcrumbName字段值用于对应locales文件中的key
 * hideBreadcrumb: true, 用于隐藏全局面包屑  可使用/components/CustomBreadcrumb实现自定义的面包屑
 * hideInMenu: true 在菜单中隐藏该页面
 * name 菜单
 * redirect 跳转  注意: 为了筛选出菜单 路由中跳转都必须使用redirect
 *
 *
 */

const routes: RouteConfig[] = [
  {
    path: "/",
    exact: true,
    render: () => {
      return <Redirect to={"/login"} />;
    },
  },
  {
    path: "/logout",
    exact: true,
    component: Logout,
  },
  {
    path: "/app",
    component: AppLayout,
    routes: [
      deals,
      // {
      //   path: "/app/home",
      //   exact: true,
      //   name: "Home",
      //   component: SuspenseComponent(Home),
      // },
      customer,
      partner,
      product,
      campaign,
      loyalty,
      loyalty_b2b,
      setting,
    ],
  },
  {
    path: "/login",
    component: lazy(() => import("../pages/login")),
  },
  {
    path: "/choose-country",
    component: lazy(() => import("../pages/choose-country")),
  },
  {
    path: "/myvetlogin",
    component: lazy(() => import("../pages/myvetlogin")),
  },
  {
    path: "/create-account",
    component: lazy(() => import("../pages/create-account")),
  },
  {
    path: "/reset-password",
    exact: false,
    component: lazy(() => import("../pages/reset-password")),
  },
  {
    path: "/forget-password",
    component: lazy(() => import("../pages/forget-password")),
  },
  {
    path: "/check-mail",
    component: lazy(() => import("../pages/check-mail")),
  },
  {
    path: "/test",
    component: lazy(() => import("../pages/test")),
  },
  {
    path: "/implicit/callback",
    component: LoginCallback,
  },
  {
    path: "/loading",
    component: Loading,
  },
];
const routesInMenu = getRoutesInMenu(routes);

export { routesInMenu };

export default routes;
