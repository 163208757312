import { useOktaAuth } from "@okta/okta-react";
import useUser from "../../hooks/useUser";
import { useEffect } from "react";
import { useHistory } from "react-router";
import "./style.less";
import { Spin } from "antd";

const LoginCallback = () => {
  const { setUser } = useUser();
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();
  useEffect(() => {
    oktaAuth.storeTokensFromRedirect();
  }, [oktaAuth]);

  useEffect(() => {
    if (authState?.accessToken) {
      sessionStorage.setItem("okta-token", authState.accessToken.value);
      if (authState.idToken) {
        sessionStorage.setItem("id-token", authState.idToken.value);
        oktaAuth.signInWithRedirect();
        return;
      }
      sessionStorage.removeItem("user-country-list");
      sessionStorage.removeItem("user-country-selected");
      history.push("/loading");
    }
  }, [authState, history, setUser]);

  return (
    <div className="login-callback">
      <Spin spinning={true} className="login-callback-spin" />
    </div>
  );
};

export default LoginCallback;
