const const_config = {

  SUCCESS_CODE: 'K-000000',

  ICON_SIZE: {fontSize: 20},

  POPUP_CONTAINER: () => document.getElementById('page-content'),
  POPUP_CONTAINER_PARENT: (node) => node.parent || document.getElementById('app'),
};

export default const_config;
