import CustomizeSkeleton from "../components/CustomizeSkeleton";
import { ComponentType, Suspense } from "react";
const SuspenseComponent =
  (Component: React.LazyExoticComponent<ComponentType<any>>) =>
  (props: JSX.IntrinsicAttributes) => {
    return (
      <Suspense fallback={<CustomizeSkeleton />}>
        <Component {...props} />
      </Suspense>
    );
  };
export default SuspenseComponent;
