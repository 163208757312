import { Spin } from "antd";
import GlobalContainer from "model/GlobalContainer";
import { useContainer } from "unstated-next";

function Spinner() {
  const { loading } = useContainer(GlobalContainer);

  if (loading) {
    return (
      <div className="full flex-center">
        <Spin size="large" />
      </div>
    );
  }

  return null;
}

export default Spinner;
