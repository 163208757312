import axios from "axios";
import intl from "react-intl-universal";
import { OpenMessage, logOut } from "../tools";
import Const from "../const_config";

const msg = {
  "K-000002": "token expired",
};

let isErrorShowing = false;

// 处理请求错误
const handleRequestError = (responseData, config) => {
  //如果returnResponse为true 直接返回responseData
  if (config.returnResponse) {
    return responseData;
  }
  if (!config.customTip && responseData.code !== Const.SUCCESS_CODE) {
    if (!isErrorShowing) {
      OpenMessage({
        success: false,
        message:
          msg[responseData.code] ||
          responseData.message ||
          intl.get(`Error.${responseData.message}`) ||
          intl.get("Public.Feedback.request_failed"),
        handleAfterClose: () => {
          isErrorShowing = false;
          if (
            ["K-000002", "K-000005", "K-000015"].includes(responseData.code)
          ) {
            logOut();
          }
        },
      });
    }

    isErrorShowing = true;
    return Promise.reject(responseData);
  } else {
    //保存当前请求时间
    if (responseData.defaultLocalDateTime) {
      sessionStorage.setItem(
        "defaultLocalDateTime",
        responseData.defaultLocalDateTime
      );
    }

    return Promise.resolve(responseData);
  }
};
// 解构Context
const handleDeconstructContext = (data) => Promise.resolve(data.context);
export const DEFAULT_REQUEST_CONFIG = {
  customTip: false,
  returnResponse: false,
  loading: false,
};

export default function request(
  params = { method: "get", url: "", params: {}, data: {} },
  config = DEFAULT_REQUEST_CONFIG
) {
  const basename = process.env.PUBLIC_URL ?? "";

  const token =
    sessionStorage.getItem("token") ||
    sessionStorage.getItem("storeToken") ||
    "";
  const URL = `${basename}/api${params.url}`;
  let axiosParams = { ...params, url: URL };
  if (token) {
    axiosParams = {
      ...axiosParams,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
  }

  return axios(axiosParams)
    .then((response) => response.data)
    .then((data) => handleRequestError(data, config))
    .then((data) => handleDeconstructContext(data));
}
