import { lazy } from "react";
import { RouteConfig } from "../typings/router";
import SuspenseComponent from "./SuspenseComponent";
const LoyaltyLayout = lazy(() => import("../layout/loyaltyLayout"));

const CustomerPointManagement = lazy(
  () => import("../pages/loyalty-b2b/customer-point-management/index")
);
const ProgramListing = lazy(
  () => import("../pages/loyalty-b2b/program-listing/index")
);
const ProgramDetails = lazy(
  () => import("../pages/loyalty-b2b/program-listing/program-details/index")
);

const loyalty_b2b: RouteConfig = {
  name: "Loyalty",
  path: "/app/loyalty-b2b",
  breadcrumbName: "Loyalty",
  component: SuspenseComponent(LoyaltyLayout),
  routes: [
    {
      path: "/app/loyalty-b2b",
      exact: true,
      redirect: "/app/loyalty-b2b/point-management",
    },
    {
      name: "Customer Point Management",
      path: "/app/loyalty-b2b/point-management",
      exact: true,
      breadcrumbName: "Customer Point Management",
      icon: "icon-user",
      component: CustomerPointManagement,
    },
    {
      name: "Program Listing",
      path: "/app/loyalty-b2b/program-listing",
      exact: true,
      breadcrumbName: "Program Listing",
      icon: "icon-category",
      component: ProgramListing,
    },
    {
      name: "Program Details",
      path: "/app/loyalty-b2b/program-listing/:id",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Program Details",
      component: ProgramDetails,
    },
  ],
};

export default loyalty_b2b;
