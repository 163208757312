import { mapKeys } from "lodash-es";

let Breadcrumb = {
  Customer: "Customer",
  "Customer List": "Customer list",
  "Customer Tagging": "Customer tagging",
  "Customer Category": "Customer category",
  "Customer Metadata": "Customer metadata",
  "Customer Detail": "Customer detail",
  "Product Detail": "Product detail",
  "Partner List": "Partner list",
  "Partner Group": "Partner group",
  "Group Member": "Group member",
  Partner: "Partner",
  "Product Group": "Product group",
  "Product Tagging": "Product tagging",
  ProductDetail: "Detail",
  Product: "Product",
  Setting: "Setting",
  "User list": "User list",
  "Group management": "Group management",
  "Role permissions": "Role permissions",
};
Breadcrumb = mapKeys(Breadcrumb, function (_, key) {
  return `Breadcrumb.${key}`;
});
export default Breadcrumb;
