import { lazy } from "react";
import { RouteConfig } from "typings/router";
import SuspenseComponent from "./SuspenseComponent";

const DealsLayout = lazy(() => import("layout/dealsLayout"));
const DealDetails = lazy(() => import("../pages/deals/details"));
const DealsTabs = lazy(() => import("../pages/deals"));

const routes: RouteConfig = {
  name: "Deals List",
  path: "/app/deals",
  component: SuspenseComponent(DealsLayout),
  routes: [
    {
      path: "/app/deals",
      exact: true,
      breadcrumbName: "Deals List",
      component: DealsTabs,
    },
    {
      exact: true,
      path: "/app/deals/:id",
      hideInMenu: true,
      component: DealDetails,
    },
  ],
};

export default routes;
