import { mapKeys } from "lodash-es";

let Menu = {
  Home: "Home",
  Customer: "Customer",
  Partner: "Partner",
  Product: "Product",
  Setting: "Setting",
  "Product List": "All products",
  "Partner Group list": "Partner group list",
  setting: "Setting",
  "product tagging": "Product tagging",
  "Customer Category": "Customer category",
  "Customer List": "Customer list",
  "Customer Tagging": "Customer tagging",
  "Customer Metadata": "Customer metadata",
  "User List": "User list",
  "Group Management": "Group management",
  "Role Permissions": "Role permissions",
  "Campaign List": "Campaign list",
  "Campaign Tags": "Campaign tags",
  "Partner List": "Partner list",
  "All Products": "All products",
  "Product Group": "Product group",
  "Product Finder": "All finder",
};
Menu = mapKeys(Menu, function (_, key) {
  return `Menu.${key}`;
});
export default Menu;
