import { Button, message, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { useHistory } from "react-router";
import {
  getCxmMenusSidebarInfo,
  postCxmOktaAuthCountries,
  postCxmOktaAuthToken,
} from "../../api/generatedByPox/services";
import { getDevJWTToken, getDevOktaAuthCountries } from "../../api/login";
import useModel from "../../hooks/useModel";
import { Enum } from "../../utils/enums";
import { logOut, OpenMessage } from "../../utils/tools";

const getCountry = (params) => {
  if (process.env.REACT_APP_DEVAPI !== "true") {
    return postCxmOktaAuthCountries(params);
  } else {
    return getDevOktaAuthCountries(params);
  }
};

const getToken = (params) => {
  if (process.env.REACT_APP_DEVAPI !== "true") {
    return postCxmOktaAuthToken(params);
  } else {
    return getDevJWTToken(params);
  }
};

const getFirstPage = (menus) => {
  if (!Array.isArray(menus) || menus.length <= 0) {
    return null;
  }
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i];
    if ((!Array.isArray(menu.routes) || menu.routes.length <= 0) && menu.path) {
      return menu;
    }
    const child = getFirstPage(menu.routes);
    if (child.path) {
      return child;
    }
  }
  return null;
};
const { countryCode } = Enum;

const Loading = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { setMenu, setFunctionMap } = useModel();
  const redirect = history.location.search;

  const basename = sessionStorage.getItem("basename");
  const idToken = sessionStorage.getItem("id-token");
  const oktaToken = sessionStorage.getItem("okta-token");
  const selectedCountry = countryCode[basename]?.value;
  const userCountry = JSON.parse(
    sessionStorage.getItem("employeeInfo") || "{}"
  )?.country;

  useEffect(() => {
    getCountryList();
  }, []);

  // 获取用户国家列表 判断当前url是否存在用户权限国家列表中
  const getCountryList = () => {
    getCountry({ oktaToken: oktaToken })
      .then((res) => {
        const countries = res.countries;
        if (countries && countries.length) {
          sessionStorage.setItem(
            "user-country-list",
            JSON.stringify(countries)
          );
          const isIncludeSelectCountry = countries.some((countrie) => {
            return countrie.code === selectedCountry;
          });
          if (!isIncludeSelectCountry) {
            message.error("This account dose not have permission!");
            setTimeout(() => {
              history.push("/logout");
            }, 2000);
            return;
          }
          handleLogin();
        }
      })
      .catch(() => {
        OpenMessage({
          success: false,
          message: "token expired",
          handleAfterClose: () => {
            logOut();
          },
        });
      });
  };

  // 传入url中的国家 获取用户token
  const handleLogin = () => {
    getToken({
      oktaToken: oktaToken,
      idToken: idToken,
      country: selectedCountry,
    })
      .then((res) => {
        sessionStorage.setItem("token", res.token);
        sessionStorage.setItem("employeeInfo", JSON.stringify(res));
        fetchAuthMenu();
      })
      .catch(() => {
        logOut();
      });
  };

  const fetchAuthMenu = async () => {
    try {
      setLoading(true);
      const { menuList, funcList } = await getCxmMenusSidebarInfo();

      if (!menuList.length) {
        Modal.confirm({
          title: intl.get("Public.Operation.title"),
          content: intl.get("Login.auth"),
          okText: intl.get("Public.Operation.confirm"),
          cancelText: intl.get("Public.Operation.retry"),
          onOk() {
            logOut();
          },
          onCancel() {
            fetchAuthMenu();
          },
        });
        return;
      }

      setMenu(menuList);
      setFunctionMap(new Map(funcList.map((item) => [item, true])));
      redirectPage(menuList);
    } catch (e) {
      message.error("Get menu failed!");
      setLoading(false);
    }
  };

  const redirectPage = (menus) => {
    const page = getFirstPage(menus);
    if (!page) {
      return;
    }
    if (redirect && userCountry === selectedCountry) {
      history.push(redirect.replace("?redirect=", ""));
      return;
    }
    history.push(page.path);
  };

  return (
    <Spin spinning={loading} className="page-center-spin">
      <div className="w-screen h-screen flex flex-center flex-column">
        {!loading ? (
          <>
            <div className="p-2 font-bold text-red-600">Get menu failed!</div>
            <Button onClick={fetchAuthMenu}>Retry</Button>
          </>
        ) : null}
      </div>
    </Spin>
  );
};

export default Loading;
