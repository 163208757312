import { lazy } from "react";
import { RouteConfig } from "../typings/router";
import SuspenseComponent from "./SuspenseComponent";
const PartnerLayout = lazy(() => import("../layout/partnerLayout"));

// partner
const PartnerList = lazy(() => import("../pages/partner/list"));
const PartnerDetail = lazy(() => import("../pages/partner/detail"));
const partner: RouteConfig = {
  name: "Partner",
  path: "/app/partner",
  breadcrumbName: "Partner",
  component: SuspenseComponent(PartnerLayout),
  routes: [
    {
      path: "/app/partner",
      redirect: "/app/partner/list",
      exact: true,
    },
    {
      name: "Partner list",
      icon: "icon-user",
      path: "/app/partner/list",
      breadcrumbName: "Partner List",
      // hideBreadcrumb: true,
      exact: true,
      component: PartnerList,
    },
    {
      path: "/app/partner/list/details/:id",
      exact: true,
      hideInMenu: true,
      component: PartnerDetail,
    },
  ],
};
export default partner;
