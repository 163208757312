import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
export default function Logout() {
  const history = useHistory();
  const LogoutFunction = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("okta-token");
    const idToken = sessionStorage.getItem("id-token");
    sessionStorage.removeItem("id-token");
    const basename = sessionStorage.getItem("basename") || "/";
    const redirectUri = window.location.origin + basename;
    if (idToken) {
      window.location.href = `${
        process.env.REACT_APP_ISSUER
      }/v1/logout?id_token_hint=${
        idToken ? idToken : ""
      }&post_logout_redirect_uri=${redirectUri}`;
    } else {
      history.push("/login");
    }
  };
  useEffect(() => {
    LogoutFunction();
  });

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Spin
        spinning={true}
        style={{
          position: "absolute",
          left: "50%",
          top: "40%",
        }}
      />
    </div>
  );
}
