const Customer = {
    "Customer.Customer": "Customer",
    "Customer.FirstName": "First name",
    "Customer.LastName": "Last name",
    "Customer.Email": "Email",
    "Customer.Gender": "Gender",
    "Customer.Birthday": "Birthday",
    "Customer.Language": "Language",
    "Customer.Tagging": "Tagging",
    "Customer.PetInformation": "Pet information",
    "Customer.PurchasePlacePreferences": "Purchase place preferences",
    "Customer.AssignedPartnerContacts": "Assigned partner contacts",
    "Customer.OwnershipHistory": "Ownership history",
    "Customer.WeightSeries": "Weight series",
    "Customer.BCSSeries": "BCS series",
    "Customer.HealthContext": "Health context",
    "Customer.SelectPetTips": "Select a pet to view information",
    "Customer.Actions": "Actions",
    "Customer.View Pet Details": "View pet details",
    "Customer.Delete": "Delete",
    "Customer.Transfer Pet": "Transfer pet",
    "Customer.Please select the pet you want to delete.":
        "Please select the pet you want to delete.",
    "Customer.Tips": "Tips",
    "Customer.Date of transfer": "Date of transfer",
    "Customer.Delete Pet": "Delete Pet",
    "Customer.Are you sure you want to delete pet":
        "Are you sure you want to delete pet?",
    "Customer.New Owner": "New owner",
    "Customer.Name": "Name",
    "Customer.neutered": "neutered",
    "Customer.PetCategory": "Pet category",
    "Customer.SterilizedDate": "Sterilized date",
    "Customer.Insured": "Insured",
    "Customer.Status": "Status",
    "Customer.Lifestyle": "Lifestyle",
    "Customer.PetID": "PetID",
    "Customer.Sensitivities": "Sensitivities",
    "Customer.ExternalID": "External ID",
    "Customer.SpecialNeeds": "Special needs",
    "Customer.Breed": "Breed",
    "Customer.FirstStageName": "First stage name",
    "Customer.Pure-breed": "Pure-breed",
    "Customer.StageEnding": "Stage ending",
    "Customer.BirthDate": "Birth date",
    "Customer.SecondStageName": "Second stage name",
    "Customer.AdultSize": "Adult size",
    "Customer.Weight": "Weight",
    "Customer.ThirdStageName": "Third stage name",
    "Customer.Activity": "Activity",
    "Customer.OwnedSince": "Owned since",
    "Customer.IdealProduct": "Ideal product",
    "Customer.DateOfVaccination": "Date of vaccination",
    "Customer.DateCreated": "Date created",
    "Customer.ChipNumber": "Chip number",
    "Customer.PetTaggings": "Pet taggings",
    "Customer.DateUpdated": "Date updated",
    "Customer.DeclarativeProduct": "Declarative product",
    "Customer.ProfileCompilation": "Profile compilation",
    "Customer.PurchasePlaces": "Purchase places",
    "Customer.Rating": "Rating",
    "Customer.RatedOn": "Rated on",
    "Customer.ContactName": "Contact name",
    "Customer.Partners": "Partners",
    "Customer.OwnerGuid": "Owner guid",
    "Customer.Owner": "Owner",
    "Customer.TransferDate": "Transfer date",
    "Customer.Date": "Date",
    "Customer.PartnerExtID": "Partner ext ID",
    "Customer.Source": "Source",
    "Customer.Weight(in grams)": "Weight (in grams)",
    "Customer.BCS": "BCS",
    "Customer.ContextCode": "Context code",
    "Customer.EndDate": "End date",
    "Customer.Male": "Male",
    "Customer.Female": "Female",
    "Customer.Recipient": "Recipient",
    "Customer.Amount": "Amount",
    "Customer.Quantity": "Quantity",
    "Customer.PrescriberName": "Prescriber name",
    "Customer.ShippingStatus": "Shipping status",
    "Customer.OrderStatus": "Order status",
    "Customer.OrderTime": "Order time",
    "Customer.Subscription": "Subscription",
    "Customer.OrderInfo": "Order info",
    "Customer.SubscriptionInfo": "Subscription info",
    "Customer.ProductName": "Product name",
    "Customer.SubscriptionType": "Subscription type",
    "Customer.PetId": "Pet ID",
    "Customer.PetName": "Pet name",
    "Customer.SubscriptionStatus": "Subscription status",
    "Customer.SubscriptionTime": "Subscription time",
    "Customer.ReceiverName": "Receiver name",
    "Customer.Address": "Address",
    "Customer.BillingAddress": "Billing address",
    "Customer.DeliveryAddress": "Delivery address",
    "Customer.AddNew": "Add new",
    "Customer.LanguageTips": "Please enter the correct language code",
    "Customer.Other": "Other",
    "Customer.Operations": "Operations",
    "CustomerList.PetOwnerUpload": "Pet owner upload",
    "CustomerList.PetUpload": "Pet upload",
    "CustomerList.Search": "Search",
    "CustomerList.Okta Id": "Okta ID",
    "CustomerList.First Name": "First name",
    "CustomerList.Last Name": "Last name",
    "CustomerList.email": "Email",
    "CustomerList.City": "City",
    "CustomerList.Created Date": "Creation date",
    "CustomerList.Update Date": "Update date",
    "CustomerList.Customer Type": "Customer type",
    "CustomerList.B2BMemberUpload": "B2B member upload",
    "CustomerUserTagging.AddNewTagging": "Add new tagging",
    "CustomerUserTagging.EditTagging": "Edit tagging",
    "CustomerUserCategory.AddNewCategory": "Add new category",
    "CustomerDetail.Contact": "Contact",
    "CustomerDetail.InternalInfo": "Internal info",
    "CustomerDetail.Preferences": "Preferences",
    "CustomerDetail.UserSegments": "User segments",
    "CustomerDetail.ConfirmedLegalForms": "Confirmed legal forms",
    "CustomerDetail.Feedback": "Feedback",
    "CustomerDetail.ProfileInfoContact.EditModalTitle": "Edit contact",
    "CustomerDetail.ProfileInfoContact.PhoneNumber": "Phone number",
    "CustomerDetail.ProfileInfoContact.Email": "Email",
    "CustomerDetail.ProfileInfoContact.Address1": "Address 1",
    "CustomerDetail.ProfileInfoContact.Address2": "Address 2",
    "CustomerDetail.ProfileInfoContact.City": "City",
    "CustomerDetail.ProfileInfoContact.Country": "Country",
    "CustomerDetail.ProfileInfoContact.PostalCode": "Postal code",
    "CustomerDetail.ProfileInfoContact.ProfileCompletion": "Profile completion",
    "CustomerDetail.ProfileInfoInternalInfo.EditModalTitle": "Edit internalInfo",
    "CustomerDetail.ProfileInfoInternalInfo.CustomerAccount": "Pet owner account",
    "CustomerDetail.ProfileInfoInternalInfo.ExternalID": "External ID",
    "CustomerDetail.ProfileInfoInternalInfo.CreatedDate": "Created date",
    "CustomerDetail.ProfileInfoInternalInfo.UpdateDate": "Update date",
    "CustomerDetail.ProfileInfoInternalInfo.Account Type": "Account type",
    "CustomerDetail.ProfileInfoInternalInfo.Internal Status": "Internal status",
    "CustomerDetail.ProfileInfoInternalInfo.Creation Origin": "Creation origin",
    "CustomerDetail.ProfileInfoInternalInfo.Creation Origin Details":
        "Creation origin details",
    "CustomerDetail.ProfileInfoInternalInfo.Activation Origin":
        "Activation origin",
    "CustomerDetail.ProfileInfoInternalInfo.Activation Origin Details":
        "Activation origin details",
    "CustomerDetail.ProfileInfoPreferences.CommunicationPreferences":
        "Communication preferences",
    "CustomerDetail.ProfileInfoPreferences.SubscriptionPreferences":
        "Subscription preferences",
    "CustomerDetail.ProfileInfoPreferences.UpdateSubscription":
        "Update subscription",
    "CustomerDetail.ProfileInfoPreferences.PurchasePlacePreferences":
        "Purchase place preferences",
    "CustomerDetail.ProfileInfoUserSegments.Customer Segments":
        "Customer segments",
    "CustomerDetail.ProfileInfoUserSegments.CoachedUserSegments":
        "Coached user segments",
    "CustomerDetail.ProfileInfoComfirmedLegalForm.ConfirmedLegalForms":
        "Confirmed legal forms",
    "CustomerDetail.ProfileInfoFeedback.After1stdelivery": "After 1st delivery",
    "CustomerDetail.ProfileInfoFeedback.After4thdelivery": "After 4th delivery",
    "CustomerDetail.ProfileInfoFeedback.Feedbackforclinics":
        "Feedback for clinics",
    "CustomerDetail.ProfileInfoFeedback.Ratedelivery": "Rate delivery:",
    "CustomerDetail.ProfileInfoFeedback.scalefrom1to5": "(scale from 1 to 5)",
    "CustomerDetail.ProfileInfoFeedback.Comments": "Comments",
    "CustomerDetail.ProfileInfoFeedback.NPS": "NPS:",
    "CustomerDetail.ProfileInfoFeedback.scalefrom1to10": "(scale from 1 to 10)",
    "CustomerDetail.ProfileInfoFeedback.Deliveryschedulefit":
        "Delivery schedule fit",
    "CustomerDetail.ProfileInfoFeedback.ReasonOfinterestOftheClub":
        "Reason of interest of the club",
    "CustomerDetail.ProfileInfoFeedback.Visit1": "Visit 1",
    "CustomerDetail.ProfileInfoFeedback.Qualityof'service1":
        "Quality of service 1:",
    "CustomerDetail.ProfileInfoFeedback.Veterinarianqualification1":
        "Veterinarian qualification 1",
    "CustomerDetail.ProfileInfoFeedback.Clinicsrating1OfthelastViSitingclinic":
        "Clinics rating 1 Of the last visiting clinic :",
    "CustomerDetail.ProfileInfoFeedback.vetCliniCCheck-up1":
        "Vet clinic check-up 1",
    "CustomerDetail.ProfileInfoFeedback.Ratethepack": "Rate the pack",
    "CustomerDetail.ProfileInfoFeedback.Visit2": "Visit 2",
    "CustomerDetail.ProfileInfoFeedback.Qualityofservice2":
        "Quality of service 2:",
    "CustomerDetail.ProfileInfoFeedback.Veterinarianqualification2":
        "Veterinarian qualification 2",
    "CustomerDetail.ProfileInfoFeedback.Clinicsrating201'thelastViSitingclinic":
        "Clinics rating 2 of the last visiting clinic :",
    "CustomerDetail.ProfileInfoFeedback.vetCliniCCheck-up":
        "Vet clinic check-up 2",
    "CustomerDetail.Partners.Add Relationship": "Add relationship",
    "CustomerDetail.Partners.Add Partner Relationship":
        "Add partner relationship",
    "CustomerDetail.Partners.Edit Partner Relationship":
        "Edit partner relationship",
    "CustomerDetail.Partners.B2B Relationships": "B2B relationships",
    "CustomerDetail.Partners.B2C Relationships": "B2C relationships",
    "CustomerDetail.Partners.Close": "Close",
    "CustomerDetail.Partners.Add": "Add",
    "CustomerDetail.Partners.Partner": "Partner",
    "CustomerDetail.Partners.Partner Type": "Partner type",
    "CustomerDetail.Partners.Relationship": "Relationship",
    "CustomerDetail.Partners.Roles": "Roles",
    "CustomerDetail.Partners.Status": "Status",
    "CustomerDetail.Partners.Application Name": "Application name",
    "CustomerDetail.Partners.Last Update": "Last Update",
    Search: "Search",
    "CustomerDetail.Tab.Profile Info": "Profile info",
    "CustomerDetail.Tab.Pet Info": "Pet info",
    "CustomerDetail.Tab.Partners": "Partners",
    "CustomerDetail.Tab.OrderInfo": "OrderInfo",
    "CustomerDetail.Tab.SubscriptionInfo": "SubscriptionInfo",
    "CustomerDetail.Tab.Address": "Address",
    "CustomerDetail.Tab.Prescriber Info": "Prescriber info",
    "CustomerDetail.Tab.Payment Methods": "Payment methods",
    "CustomerDetail.Tab.Benefits": "Benefits",
    "CustomerDetail.Tab.Events": "Events",
    "CustomerDetail.Confirmed Place": "Confirmed place",
    "CustomerDetail.Name": "Name",
    "CustomerDetail.Date": "Date",
    "CustomerDetail.Document": "Document",
    "CustomerDetail.PrescriberInfo.Prescriber information":
        "Prescriber Information",
    "CustomerDetail.PrescriberInfo.Prescriber ID": "Prescriber ID",
    "CustomerDetail.PrescriberInfo.Prescriber name": "Prescriber name",
    "CustomerDetail.PrescriberInfo.Prescriber phone": "Prescriber phone",
    "CustomerDetail.PrescriberInfo.Prescriber city": "Prescriber city",
    "CustomerDetail.PrescriberInfo.Prescriber type": "Prescriber type",
    "CustomerDetail.PaymentMethods.Add New Card": "Add new card",
    "CustomerDetail.PaymentMethods.Card number": "Card number",
    "CustomerDetail.PaymentMethods.Card type": "Card type",
    "CustomerDetail.PaymentMethods.Card holder": "Card holder",
    "CustomerDetail.PaymentMethods.E-mail address": "E-mail address",
    "CustomerDetail.PaymentMethods.Phone number": "Phone number",
    "CustomerDetail.PaymentMethods.Operation": "Operation",
    "CustomerDetail.Benefit.Benefit": "Benefit",
    "CustomerDetail.Benefit.Benefit type": "Benefit type",
    "CustomerDetail.Benefit.Gift name": "Gift name",
    "CustomerDetail.Benefit.Gift type": "Gift type",
    "CustomerDetail.Benefit.Delivery number": "Delivery number",
    "CustomerDetail.Benefit.SPU": "SPU",
    "CustomerDetail.Benefit.SKU": "SKU",
    "CustomerDetail.Benefit.Status": "Status",
    "CustomerDetail.Benefit.Scheduled time": "Scheduled time",
    "CustomerDetail.Benefit.Delivery time": "Delivery time",
    "CustomerDetail.Events.Tab.Event History": "Event history",
    "CustomerDetail.Events.Tab.Subscription History": "Subscription history",
    "CustomerDetail.Events.Event History.Date/Time": "Date/Time",
    "CustomerDetail.Events.Event History.Email Reference": "Email reference",
    "CustomerDetail.Events.Event History.Source": "Source",
    "CustomerDetail.Events.Event History.Event": "Event",
    "CustomerDetail.Events.Event History.Partner": "Partner",
    "CustomerDetail.Events.Event History.Category": "Category",
    "CustomerDetail.Events.Event History.Location": "Location",
    "CustomerDetail.Events.Event History.IP": "IP",
    "CustomerDetail.Events.Subscription History.Date/Time": "Date/Time",
    "CustomerDetail.Events.Subscription History.Source": "Source",
    "CustomerDetail.Events.Subscription History.Event": "Event",
    "CustomerDetail.Events.Subscription History.Subscription": "Subscription",
    Subscription: "Subscription",
    MetaData: "MetaData",
    "CustomerMetadata.Tab.Titles": "Titles",
    "CustomerMetadata.Tab.Genders": "Genders",
    "CustomerMetadata.Tab.Communication Preferences": "Communication preferences",
    "CustomerMetadata.Tab.Subscriptions": "Subscriptions",
    "CustomerMetadata.Tab.Places of Purchase": "Places of Purchase",
    "CustomerMetadata.Tab.Access Rights": "Access rights",
    "CustomerMetadata.Tab.Legal Forms": "Legal Forms",
    "CustomerMetadata.Subscriptions.Subscription Code": "Subscription code",
    "CustomerMetadata.Subscriptions.Subscription Name": "Subscription name",
    "CustomerMetadata.Subscriptions.Subscription Type": "Subscription type",
    "CustomerMetadata.Subscriptions.Operation": "Operation",
    "CustomerMetadata.Subscriptions.Search": "Search",
    "CustomerMetadata.Genders.Generic Name": "Generic name",
    "CustomerMetadata.CommunicationPreferences.Code": "Code",
    "CustomerMetadata.CommunicationPreferences.Name": "Name",
    "CustomerMetadata.CommunicationPreferences.Edit": "Edit",
    "CustomerMetadata.CommunicationPreferences.Generic Name": "Generic name",
    "CustomerMetadata.CommunicationPreferences.Edit Communication Preferences":
        "Edit communication preferences",
    "CustomerMetadata.CommunicationPreferences.Add Communication Preferences":
        "Add communication preferences",
    "CustomerMetadata.Genders.Add Genders": "Add genders",
}

export default Customer;
