import { lazy } from "react";
import { RouteConfig } from "../typings/router";
import SuspenseComponent from "./SuspenseComponent";
const LoyaltyLayout = lazy(() => import("../layout/loyaltyLayout"));

//Loyalty index
const CustomerPointManagement = lazy(
  () => import("../pages/loyalty/customer-point-management/index")
);
const TrackRecords = lazy(
  () =>
    import(
      "../pages/loyalty/customer-point-management/components/TrackRecords/index"
    )
);
const ProgramListing = lazy(
  () => import("../pages/loyalty/program-listing/index")
);

const ProgramDetails = lazy(
  () => import("../pages/loyalty/program-listing/components/programShowDetails")
);

const ProgramShowDetails = lazy(
  () => import("../pages/loyalty/program-listing/components/ProgramDetails")
);

const loyalty: RouteConfig = {
  name: "Loyalty",
  path: "/app/loyalty",
  breadcrumbName: "Loyalty",
  component: SuspenseComponent(LoyaltyLayout),
  routes: [
    {
      path: "/app/loyalty",
      exact: true,
      redirect: "/app/loyalty/point-management",
    },
    {
      name: "Customer  Point  Management",
      path: "/app/loyalty/point-management",
      exact: true,
      breadcrumbName: "Customer Point Management",
      icon: "icon-user",
      component: CustomerPointManagement,
    },
    {
      path: "/app/loyalty/point-management/track-records/:id",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Track Records",
      component: TrackRecords,
    },
    {
      name: "Program Listing",
      path: "/app/loyalty/program-listing",
      exact: true,
      breadcrumbName: "Program Listing",
      icon: "icon-category",
      component: ProgramListing,
    },
    {
      path: "/app/loyalty/program-listing/program-details/:id?/:show?",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Program Details",
      component: ProgramDetails,
    },
    {
      path: "/app/loyalty/program-listing/program-details-show/:id",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Program Details",
      component: ProgramShowDetails,
    },
  ],
};

export default loyalty;
