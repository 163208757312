import { RouteConfig } from "../typings/router";

// 获取菜单路由
export function getRoutesInMenu(routes: RouteConfig[]): RouteConfig[] {
  return routes
    .filter((route) => !route.redirect && !route.hideInMenu)
    .map((route) =>
      route.routes ? { ...route, routes: getRoutesInMenu(route.routes) } : route
    );
}
