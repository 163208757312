import { lazy } from "react";
import { RouteConfig } from "../typings/router";
import SuspenseComponent from "./SuspenseComponent";
// Customer
const PoLayout = lazy(() => import("../layout/poLayout"));
const CustomerList = lazy(() => import("../pages/customer/list"));
const CustomerDetail = lazy(() => import("../pages/customer/detail"));
// const CustomerMetadata = lazy(() => import("../pages/customer/metadata"));
const customer: RouteConfig = {
  name: "Customer",
  path: "/app/customer",
  breadcrumbName: "Customer",
  component: SuspenseComponent(PoLayout),
  routes: [
    {
      name: "Customer List",
      path: "/app/customer/list",
      icon: "icon-team",
      component: CustomerList,
      exact: true,
    },
    // {
    //   name: "Customer Metadata",
    //   breadcrumbName: "Customer Metadata",
    //   hideBreadcrumb: true,
    //   icon: "icon-database",
    //   path: "/app/customer/metadata",
    //   exact: true,
    //   component: CustomerMetadata,
    // },
    {
      path: "/app/customer/list/detail/:id",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Customer Detail",
      component: CustomerDetail,
    },
    // {
    //   path: "/app/customer",
    //   redirect: "/app/customer/list",
    //   exact: true,
    // },
  ],
};
export default customer;
