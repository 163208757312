import { lazy } from "react";
import { RouteConfig } from "../typings/router";
import SuspenseComponent from "./SuspenseComponent";

const SettingLayout = lazy(() => import("../layout/settingLayout"));
const AuthorityManage = lazy(() => import("../pages/setting/authority-manage"));

const setting: RouteConfig = {
  name: "Setting",
  path: "/app/setting",
  breadcrumbName: "Setting",
  component: SuspenseComponent(SettingLayout),
  routes: [
    {
      path: "/app/setting",
      exact: true,
      redirect: "/app/setting/authority-manage",
    },
    {
      name: "Authority Manage",
      path: "/app/setting/authority-manage",
      breadcrumbName: "Authority Manage",
      exact: true,
      icon: "icon-team",
      component: AuthorityManage,
    },
  ],
};

export default setting;
