import { lazy } from "react";
import { RouteConfig } from "../typings/router";
import SuspenseComponent from "./SuspenseComponent";
const ProductLayout = lazy(() => import("../layout/productLayout"));
/*注释const ProductTagging = lazy(() => import('../pages/product/tagging'));*/
const ProductCollectionList = lazy(
  () => import("../pages/product/collection/list")
);
const ProductCollectionRelated = lazy(
  () => import("../pages/product/collection/related")
);
const ProductCollectionListDetail = lazy(
  () => import("../pages/product/collection/detail")
);
const ProductCollectionReference = lazy(
  () => import("../pages/product/collection/productFinderReferences")
);
const Products = lazy(() => import("../pages/product/products"));
const ProductsDetail = lazy(() => import("../pages/product/products/detail"));
const ProductFinderSimulator = lazy(
  () => import("../pages/product/finder/simulator")
);
const ProductFinderSimulatorResults = lazy(
  () => import("../pages/product/finder/results")
);
const ProductFinderSimulatorResultsDetail = lazy(
  () => import("../pages/product/finder/detail")
);

const product: RouteConfig = {
  name: "Product",
  path: "/app/product",
  breadcrumbName: "Product",
  component: SuspenseComponent(ProductLayout),
  routes: [
    {
      name: "Collections",
      path: "/app/product/collection",
      exact: true,
      icon: "icon-Similarproducts",
      breadcrumbName: "Collections",
      component: ProductCollectionList,
    },
    {
      path: "/app/product/collection/:id",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Details",
      component: ProductCollectionListDetail,
    },
    {
      path: "/app/product/collection/references/:id",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Product Finder References",
      component: ProductCollectionReference,
    },
    {
      path: "/app/product/collection-related",
      exact: true,
      breadcrumbName: "Collections with Related Products",
      hideInMenu: true,
      component: ProductCollectionRelated,
    },
    {
      name: "Product List",
      path: "/app/product/products",
      exact: true,
      icon: "icon-product",
      breadcrumbName: "Products",
      component: Products,
    },
    {
      path: "/app/product/products/:id",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "ProductDetail",
      component: ProductsDetail,
    },
    {
      name: "Product Finder",
      path: "/app/product/product-finder",
      exact: true,
      breadcrumbName: "Product Finder",
      hideInMenu: true,
      redirect: "/app/product/product-finder/simulator",
    },
    {
      name: "Product Finder",
      path: "/app/product/product-finder/simulator",
      exact: true,
      breadcrumbName: "Simulator",
      icon: "icon-sousuo",
      component: ProductFinderSimulator,
    },
    {
      name: "Results",
      path: "/app/product/product-finder/simulator/result",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Results",
      component: ProductFinderSimulatorResults,
    },
    {
      name: "Detail",
      path: "/app/product/product-finder/simulator/result/detail/:id",
      exact: true,
      hideInMenu: true,
      breadcrumbName: "Detail",
      component: ProductFinderSimulatorResultsDetail,
    },
    {
      path: "/app/product",
      redirect: "/app/product/collection",
      exact: true,
    },
  ],
};
export default product;
