import breadcrumb from "./breadcrumb";
import menu from "./menu";
import error from "./error";
import pages from "./pages";
const en_US = {
  ...error,
  ...menu,
  ...breadcrumb,
  ...pages,
};

export default en_US;
