import React from "react";
import { Modal } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import intl from "react-intl-universal";
import { postCxmOktaAuthLoginOut } from "../../api/generatedByPox/services";

export const isProdEnv = !!process.env.PUBLIC_URL;

/**
 * 因客户需求 用户可以在地址 host 后面加国家代码 区分国家地址 /fr /jp /us ....
 * 如：https://xxxx/fr/login 或 https://xxxx/jp/login
 * 但是同一套代码 同一套服务器 只能前台路由Router 加 basename 来实现
 * 动态获取浏览器地址来添加 basename, 默认 /fr
 **/

export const getBasename = () => {
  const href = window.location.href;
  const reg = /http[s]?:\/\/[^\/]+\/([a-z]{2,4})\/*/i;
  const countryCode = href.match(reg) && href.match(reg)[1];

  let basename = "/fr";
  if (["fr", "jp", "it", "au", "nz"].includes(countryCode)) {
    basename = `/${countryCode}`;
  }
  sessionStorage.setItem("basename", basename);
  return basename;
};

export const logOut = () => {
  const basename = sessionStorage.getItem("basename");
  postCxmOktaAuthLoginOut().then(() => {
    window.location.pathname = `${basename}/logout`;
  });
};

export const isMobileApp = () => {
  const devices = navigator.userAgent.toLowerCase();
  return /iphone/.test(devices) || /android/.test(devices);
};

export const OpenMessage = ({ success, message, handleAfterClose }) => {
  const config = {
    icon: null,
    centered: true,
    maskClosable: true,
    closable: false,
    okButtonProps: { style: { display: "none" } },
    afterClose: () => {
      if (handleAfterClose) {
        handleAfterClose();
      }
    },
  };
  if (isMobileApp()) {
    Object.assign(config, {
      className: "rc-mobile-modal",
      content: (
        <div className="align-item-center">
          <div className="vpadding-level-2 word big bold">
            {success
              ? intl.get("Public.Feedback.success")
              : intl.get("Public.Feedback.error")}
          </div>
          <div className="vpadding-level-2 word big">{message}</div>
        </div>
      ),
      okButtonProps: { style: { display: "none" } },
      cancelButtonProps: {
        type: "link",
        size: "large",
        style: { width: "100%" },
      },
      cancelText: intl.get("Public.Operation.confirm"),
      width: "80%",
    });
  } else {
    Object.assign(config, {
      className: "rc-desktop-modal",
      title: intl.get("Public.Operation.title"),
      closable: true,
      width: 360,
      content: (
        <div className="align-item-center">
          {success ? (
            <CheckCircleFilled
              className="hmargin-level-1"
              style={{ color: "#52c41a", fontSize: 16 }}
            />
          ) : (
            <CloseCircleFilled
              className="hmargin-level-1"
              style={{ color: "#f5222d", fontSize: 16 }}
            />
          )}
          <span
            className="word big bold inline-flex"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      ),
      okButtonProps: { style: { display: "none" } },
      cancelButtonProps: { style: { display: "none" } },
    });
  }
  Modal.confirm(config);
};

export const ConfirmAction = (message, handler) => {
  Modal.confirm({
    title: intl.get("Public.Feedback.need_you_confirm"),
    content: message || intl.get("Public.Feedback.default_tip"),
    okText: intl.get("Public.Operation.confirm"),
    cancelText: intl.get("Public.Operation.cancel"),
    onOk() {
      if (handler) {
        handler();
      }
    },
  });
};

export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * 以引用的特性构建tree
 */
export const array2Tree = (arr, opt = {}) => {
  if (!Array.isArray(arr) && !arr.length) {
    return [];
  }
  const DEFAULT_OPT = {
    identifyKey: "id",
    parentKey: "pid",
    childrenKey: "children",
  };
  opt = Object.assign({}, DEFAULT_OPT, opt);
  const root = {
    [opt.childrenKey]: [],
  };
  arr.forEach((item, _, arr) => {
    const p = arr.find((it) => it[opt.identifyKey] === item[opt.parentKey]);
    if (!p) {
      root[opt.childrenKey][root[opt.childrenKey].length] = item;
    } else {
      p[opt.childrenKey] = p[opt.childrenKey] || [];
      p[opt.childrenKey][p[opt.childrenKey].length] = item;
    }
  });
  return root[opt.childrenKey];
};

export const getThousandsFormat = (num) => {
  if (typeof num !== "number") return num;
  if (num === 0) return num;
  let [integer, decimal] = String.prototype.split.call(num, ".");
  integer = (integer || 0).toString();
  let result = "";
  while (integer.length > 3) {
    result = "," + integer.slice(-3) + result;
    integer = integer.slice(0, integer.length - 3);
  }
  if (integer) {
    result = integer + result;
  }
  return `${result}${decimal ? "." + decimal : ""}`;
};

export const getOrdinal = (n) => {
  if (n === "" || n === undefined || n === null) {
    return "";
  }
  let ord = ["st", "nd", "rd"];
  let exceptions = [11, 12, 13];
  let nth =
    ord[(n % 10) - 1] === undefined || exceptions.includes(n % 100)
      ? "th"
      : ord[(n % 10) - 1];
  return n + nth;
};
